import React, { FunctionComponent } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage: FunctionComponent = () => (
  <Layout>
    <SEO title="404: Pagina niet gevonden" />
    <h1>Pagina niet gevonden</h1>
    <p>De gevraagde pagina kon niet gevonden worden.</p>
  </Layout>
);

export default NotFoundPage;
